<template>
    <div>
        <a-card style="margin-bottom:20px">
            <a-form layout="inline">
                
                <a-form-item class="dep" label="敏感词:">
                    <a-input v-model="searchForm.word" placeholder="敏感词"></a-input>
                </a-form-item>
                <a-form-item label="报警日期">
                    <a-range-picker @change="handleDateChange" v-model="dateRange"/>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" icon="search" @click="handleClickSearch">
                        搜索
                    </a-button>
                </a-form-item>
                <a-form-item>
                    <a-button 
                        icon="reload"
                        @click="resetSearchForm">
                        重置
                    </a-button> 
                </a-form-item>
            </a-form>
        </a-card>
        <a-card>
            <a-table
                :columns="columns"
                :data-source="tableData"
                :row-key="(record) => record._id"
                :pagination="pagination"
                :loading="loading"
                @change="handleTableChange"
                >
                <a-tag slot="status" slot-scope="text" >{{text===1?'个人':text===2?'群聊':'未知'}}</a-tag>
                <a-tag slot="tag" slot-scope="text" color="blue">{{text}}</a-tag>
                <span slot="time" slot-scope="text">{{ text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}</span>
            </a-table>
        </a-card>
    </div>
</template>

<script>
const columns = [
     {
      title: "内容",
      dataIndex: "content",
      align: "center",
      ellipsis: true
    },
    {
      title: "敏感词",
      dataIndex: "word",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'tag' },
    },
    {
      title: "聊天类型",
      dataIndex: "type",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'status' },
    },
    {
      title: "发送小助手",
      dataIndex: "user_id",
      align: "center",
      ellipsis: true,
    },
    {
      title: "接收者",
      dataIndex: "receiver",
      align: "center",
      ellipsis: true,
    },
    {
      title: "触发时间",
      dataIndex: "create_time",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'time' },
    }
]
import {
    mapState
  } from 'vuex'
export default {
    name:'log',
    data(){
        return{
            searchForm:{
                word:"",
                begin_time:null,
                end_time:null
            },
            dateRange:undefined,
            columns,
            tableData:[],
            loading:false,
            pagination:{
                total: 0,
                pageSize: 20,//每页中显示10条数据
                current: 1,
                showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            }
        }
    },
    computed: {
        ...mapState(['mqttInit'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleSearch()
                }
            },
            immediate:true
        }
    },
    methods:{
        handleDateChange (date, dateString) {
            // console.log(dateString);
            this.searchForm.begin_time = new Date(dateString[0]).getTime()
            this.searchForm.end_time = new Date(dateString[1]).getTime()
        },
        handleClickSearch(){
            this.pagination.current = 1
            this.handleSearch()
        },
        resetSearchForm(){
            this.searchForm = {
                word:"",
                begin_time:null,
                end_time:null
            }
        },
        handleTableChange(pagination){
            this.pagination = pagination
            this.handleSearch()
        },
        handleSearch(){
            this.loading = true  
             let payload = {
                msgId:'searchSensitive',
                data:{
                    page:this.pagination.current,
                    word:this.searchForm.word
                }
            }
            if(this.searchForm.begin_time){
                payload.data.begin_time = this.searchForm.begin_time
            }
            if(this.searchForm.end_time){
                payload.data.end_time = this.searchForm.end_time
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/risk/searchSensitive",
                payload,
                },
                (topic, data) => {
                    this.loading = false
                    this.tableData = data.res
                    this.pagination.total = data.count
                },
                this
            );
        }
    }
}
</script>
<style lang="scss" scoped>

</style>